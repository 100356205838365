import userTypes from "@elsa-esa/lib/constants/userTypes";
import { AuthData, getUser } from "src/js/helpers/checkUser";
import { isChangeAccount, redirect } from "src/js/helpers/urlParams";
import { getUsers } from "@elsa-esa/lib/helpers/auth";

const isProd = process.env.NODE_ENV === 'production';

const redirectTo = (href: string) => window.location.href = href + (isProd ? '' : '.html') + location.search;

export const redirectToLogin = () => redirectTo('/auth/login');

export const redirectToSelect = () => redirectTo('/auth/select-account');

export const redirectToUserPanel = (user: AuthData['user']) => {
  const { type_user } = user;
  const userType = userTypes.dict[type_user];
  if (!userType) return alert('Неизвестный тип пользователя');

  let newHref = userType.baseUrl;
  if (redirect && redirect.indexOf(userType.baseUrl) === 0) newHref = redirect;
  window.location.href = newHref;
};

export const redirectFromRoot = () => {
  getUsers().then(({ current, others }) => {
    if (getUser(current) && !isChangeAccount) return redirectToUserPanel(getUser(current));
    if (current || others.length) return redirectToSelect();
    redirectToLogin();
  });
};

export const redirectFromSelectAccount = () => {
  getUsers().then(({ current, others }) => {
    if (getUser(current) && !isChangeAccount) return redirectToUserPanel(getUser(current));
    if (!current && !others.length) return redirectToLogin();
  });
}

export const redirectFromDefault = () => {
  getUsers().then(({ current, others }) => {
    if (getUser(current) && !isChangeAccount) return redirectToUserPanel(getUser(current));
  });
}